<template>
	<main class="page-container" id="join-loading">
		<div class="mbrp-loading">
			<strong class="color-orange">통합 로그인 진행중 입니다</strong>
			<p>잠시만 기다려 주세요</p>
			<div class="loading-container is-active">
				<div class="loading"></div>
			</div>
		</div>
	</main>
</template>
<script>
import { mapGetters } from 'vuex';
import {
  ACT_AFTER_SSO_LOGIN_USER,
  ACT_GET_SESSION,
  ACT_GET_SSO_USER_INFO,
  ACT_SET_AUTH_TOKEN, ACT_UPDATE_LGN_FAIL_MTRY_YMD, ACT_UPDATE_SSO_LGN_FAIL_MTRY_YMD
} from "@/store/_act_consts";
import {getItem, isSuccess, lengthCheck} from "@/assets/js/utils";
import {MUT_SET_RETURN_ROUTE, MUT_SET_SESSION, MUT_SHOW_ALERT, MUT_SHOW_CONFIRM} from "@/store/_mut_consts";
import async from "async";

export default {
	name: 'JoinLoading',
	components: {},
	computed: {
		...mapGetters('auth', ['session', 'isAuth', 'checkMeInfo']),
		...mapGetters('common', ['isMobile']),
		...mapGetters('join', ['userNm']),
    encData() {
      return this.$route.query.encData;
    },
    encKey() {
      return this.$route.query.encKey;
    }
	},
	watch: {
  },
	data: () => ({
    lgnFailCode : '',
    lgnFailMessage : '',
  }),
	mounted() {
    this.getSsoUserInfo()
  },
	methods: {
    getSsoUserInfo() {
      if (!this.encData || !this.encKey) {
        this.$router.push({name: 'Login'})
      }
      const params = {
        encData: this.encData,
        encKey: this.encKey
      };

      this.$store.dispatch(`auth/${ACT_AFTER_SSO_LOGIN_USER}`, params).then( res => {

        this.lgnFailCode = res.result.code;
        this.lgnFailMessage = res.result.message;

        if (isSuccess(res)) {
          this.$store.dispatch(`auth/${ACT_SET_AUTH_TOKEN}`, res.authToken).then(() => {
            this.$store.dispatch(`auth/${ACT_GET_SESSION}`).then(resp => {
              if (lengthCheck(resp)) {
                const session = getItem(resp);
                this.$store.commit(`auth/${MUT_SET_SESSION}`, session);
                this.$router.push({name: 'Main'});
              } else {
                this.viewError(this.lgnFailCode, this.lgnFailMessage);
              }
            })
          })
        } else {
          this.viewError(this.lgnFailCode, this.lgnFailMessage);
        }
      }).catch(e => {
          console.error(e);
          this.viewError(this.lgnFailCode, this.lgnFailMessage);
      });
    },
    viewError(code, message) {
      this.isLoading = false;
      if (code !== undefined) {
        this.isError = code;
        if (code === 'LGN_FAIL_TMS'){
          this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
            title: message,
            yesfunc: this.findPassword,
            html:true
          });
        }else if(code ==='LGN_FAIL_MTRY_YMD'){
          this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
            title: message,
            confirmButtonText: '다음에 변경하기',
            cancelButtonText: '변경하기',
            showCancelButton: true,
            html:true,
            yesfunc:this.updateLgnFailMtry,
            nofunc:this.findPassword,
          });
        }
      } else {
        this.isError = "";
      }
    },
    findPassword(){
      this.$router.push({name: 'FindChoice', params: {division: 'password'}});
    },
    updateLgnFailMtry(){
      // this.params.lgnId = this.lgnId.trim();
      // 사용자의 토근 정보를 api로 전송
      // 사용자의 비밀 번호 연기 완료 결과가 성공일 경우 getSsoUserInfo() 호출
      // 실패 시
      const params = {
        encData: this.encData,
        encKey: this.encKey
      }
      this.$store.dispatch(`auth/${ACT_UPDATE_SSO_LGN_FAIL_MTRY_YMD}`, params).then(res=>{
        if(isSuccess(res)){
          this.getSsoUserInfo();
        }
      });
    },
  },
	destroyed() {},
};
</script>
